
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class RecoverPassword extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  $refs!: {
    recoverPasswordForm: HTMLFormElement;
  };
  loading = false;

  user = {
    identification: "",
  };

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  @Watch("language")
  resetValidations() {
    this.resetFormValidations([this.$refs.recoverPasswordForm]);
  }

  private async submit() {
    if (this.$refs.recoverPasswordForm.validate()) {
      this.loading = true;
      await this.$store
        .dispatch("authentication/recoverPassword", this.user)
        .then(() => {
          this.loading = false;
          this.navigate("/email-sent");
        })
        .catch(() => {
          let errorMessage: string = this.$tc("RecoverPassword.error");
          const Error: Notification = {
            message: errorMessage,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
